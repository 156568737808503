import React from 'react';
import './App.css';

import HengDeDonation from './HengDeDonation';

function App() {
  return (
    <HengDeDonation />
  );
}

export default App;
