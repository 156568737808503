import React, { useState } from 'react';

import HengDeGraph from './HengDeGraph';
import DonationList from './DonationList';

const HengDeDonation = props => {
  const [ showList, setShowList ] = useState(false);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 20,
      backgroundColor: '#000000',
      color: 'yellow',
      paddingBottom: 100
    },
    title: {
      marginTop: 30,
      fontSize: '2.0em'
    },
    description: {
      textAlign: 'center',
      color: '#00eeee',
      fontSize: '1.5em'
    },
    donationContainer: {
      marginTop: 30
    },
    donationList: {
      cursor: 'pointer'
    }
  }

  const handleShowList = _ => {
    setShowList(true);
  }

  return (
    <div style={styles.container}>
      <div style={styles.title}>恆德建設籌款目標</div>
      <HengDeGraph />
      <div style={styles.description}>
        <p>「天上一葉蓮，人間一佛堂」。</p>
        <p>道場建設仍在進行中，尚須您的護持！ (因疫情使得進度延緩、建材、工資上漲)</p>
        <p>「有事建功易，無事建功難」。</p>
        <p>請各位功德主慷慨解囊，有錢出錢、有力出力（廣為宣傳），讓我們一起努力，祈願東灣這艘大法船早日完工！</p>
        <p>感謝大家的護持。</p>
      </div>
      { !showList && (
        <div 
          style={{...styles.donationContainer, ...styles.donationList}}
          onClick={handleShowList}
        >顯示籌款名單</div>
      )}

      { showList &&  (
        <div style={styles.donationContainer}>
          <DonationList />
        </div>
      )}
    </div>
  )
}

export default HengDeDonation;