import React from 'react';
import { Column } from '@ant-design/plots';

import { formatter } from './util/utils';
import { jsonData } from './util/donationData';

const HengDeGraph = props => {
  
  const config = {
    data: jsonData,
    xField: 'product_type',
    yField: 'order_amt',
    isGroup: false,
    isStack: true,
    seriesField: 'product_sub_type',
    groupField: 'product_type',
    xAxis: {
      label: {
        style: {
          fontSize: 20
        }
      }
    },
    yAxis: {
      label: {
        formatter: (val) => {
          return formatter.format(val)
        }
      }
    },
    label: {
        fontSize: 80
    },
    animation: {
      // Configuration of the first animation
      appear: {
        animation: 'scale-in-y', // Effects of the first animation
        duration: 5000, // Duration of the first animation
      },
    }
  };

  return (
    <div style={{width: '60%', height: '80vh', padding: 50}}>
      <Column {...config} />
    </div>
  );
};


export default HengDeGraph;