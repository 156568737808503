import React from 'react';

import { formatter } from './util/utils';
import { jsonData } from './util/donationData';

const DonationList = _ => {

  // sort by aamount
  jsonData.sort((a, b) => {
    if (a.order_amt > b.order_amt) {
      return -1;
    }
    if (a.order_amt < b.order_amt) {
      return 1;
    }
    return 0;
  });

  const styles = {
    donationContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center'
    },
    title: {
      fontSize: '1.5em',
      paddingTop: 10,
      paddingBottom: 5
    },
    donationList: {
      padding: 5
    }
  };

  return (
    <div style={styles.donationContainer}>
      <div style={styles.title}>籌款名單</div>
      {
        jsonData.map((data, i) => {
          return (data.product_type !== '目標') && (
              <div key={i} style={styles.donationList}>{data.product_sub_type} ({formatter.format(data.order_amt)})</div>
            )
          })
      }
    </div>
  )
}

export default DonationList;