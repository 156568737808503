const TARGET = 800_000;

const jsonData = [
  {
    "product_type": "目標",
    "order_amt": TARGET,
    "product_sub_type": "我們的目標"
  },
  {
    "product_type": "現況",
    "order_amt": 20_000,
    "product_sub_type": "明德壇"
  },
  {
    "product_type": "現況",
    "order_amt": 2000,
    "product_sub_type": "Anna Z"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "林...初闔家"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "陳...水闔家"
  },
  {
    "product_type": "現況",
    "order_amt": 10_000,
    "product_sub_type": "曾氏佛堂"
  },
  {
    "product_type": "現況",
    "order_amt": 1800,
    "product_sub_type": "廣州林氏佛堂"
  },
  {
    "product_type": "現況",
    "order_amt": 200,
    "product_sub_type": "徐...芬"
  },
  {
    "product_type": "現況",
    "order_amt": 300,
    "product_sub_type": "謝...美"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "Devone Beauty Spa"
  },
  {
    "product_type": "現況",
    "order_amt": 50,
    "product_sub_type": "Kingston"
  },
  {
    "product_type": "現況",
    "order_amt": 50,
    "product_sub_type": "Isaac"
  },
  {
    "product_type": "現況",
    "order_amt": 1500,
    "product_sub_type": "曾氏佛堂"
  },
  {
    "product_type": "現況",
    "order_amt": 600,
    "product_sub_type": "熊...丹"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "伍...瓊"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "林...雄"
  },
  {
    "product_type": "現況",
    "order_amt": 100,
    "product_sub_type": "李...群"
  },
  {
    "product_type": "現況",
    "order_amt": 20,
    "product_sub_type": "陳...彥"
  },
  {
    "product_type": "現況",
    "order_amt": 2000,
    "product_sub_type": "秦...獻"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "許...英"
  },
  {
    "product_type": "現況",
    "order_amt": 200,
    "product_sub_type": "劉...玲"
  },
  {
    "product_type": "現況",
    "order_amt": 200,
    "product_sub_type": "李...群"
  },
  {
    "product_type": "現況",
    "order_amt": 10,
    "product_sub_type": "William Zh..."
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "吳...瓊"
  },
  {
    "product_type": "現況",
    "order_amt": 44,
    "product_sub_type": "Annabel Fu..."
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "邱...楓"
  },
  {
    "product_type": "現況",
    "order_amt": 1000,
    "product_sub_type": "雷...連"
  }
];

export {
  jsonData
}